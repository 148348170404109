html {
  font-family: $primary-font, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: $navy-blue;
}

h1,
h2,
h3 {
  font-family: $secondary-font;
}

h1,
h2 {
  font-size: 2rem;
  font-variant-numeric: tabular-nums;
  text-transform: lowercase;
  transform: scale(1.3333);
  transition: transform 0.4s cubic-bezier(0.16, 1, 0.2, 1);
  transform-origin: bottom left;

  @include responsive('medium') {
    font-size: 2.5rem;
  }
  @include responsive('large') {
    font-size: $fs-large;
  }
}

h3 {
  font-size: 1.6rem;
  line-height: 130%;
  @include responsive('large') {
    font-size: 2.15rem;
  }
}

h4 {
  font-size: 1.1rem;
  font-weight: 500;
  @include responsive('large') {
    font-size: 1.25rem;
  }
}

a,
.link {
  text-decoration: none;
}

p {
  font-size: inherit;
  line-height: 170%;
}

small {
  font-size: .7rem;
  line-height: 170%;
}

.sub-header {
  font-size: 1.05rem;
  line-height: 170%;
}

input {
  font-family: inherit;
  font-size: inherit;
}