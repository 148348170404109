.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  &--down {
    background-image: url('../images/icons/arrow-down.svg')
  }
  &--search {
    background-image: url('../images/icons/search.svg');
  }
  &--search-white {
    background-image: url('../images/icons/search--white.svg');
  }
  &--slider {
    background-image: url('../images/icons/slider.svg');
  }
  &--arrow-up-right {
    background-image: url('../images/icons/arrow-up-right.svg');
  }
}