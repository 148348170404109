.profile {
  &--grid {
    display: grid;
    column-gap: 48px;
    padding: 0 0 60px 24px;
    @include responsive('large') {
      grid-template-columns: .45fr 1fr;
      padding: 0 24px 60px;
    }
    @include responsive('x-large') {
      grid-template-columns: .35fr 1fr;
    }
  }
  &__about {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    & + div {
      padding-top: 32px;
    }
    @include responsive('medium') {
      border-right: 1px solid rgba($navy-blue, .15);
      padding-right: 40px;
      padding-top: 32px;
    }
  }
  &__name {
    padding: 20px 0 0;
    text-transform: capitalize;
    & + a {
      display: inline-block;
      font-size: .95rem;
      font-weight: 500;
      margin: 18px 0 0;
    }
  }
  &__role {
    display: block;
    font-weight: 500;
    letter-spacing: .025rem;
    padding-bottom: 30px;
    text-transform: uppercase;
  }
  &__thumbnail {
    --dimension: 64px;
    border-radius: 8px;
    height: var(--dimension);
    object-fit: cover;
    width: var(--dimension);
    @include responsive('medium') {
      --dimension: 96px;
    }
  }
  &__info {
    word-break: break-word;
    p {
      color: rgba($navy-blue, .8);
      margin-top: 18px;
    }
  }
}