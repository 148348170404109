.hero {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 0 32px;
  --hgt: 0px;
  @keyframes slideUpOut {
    from {
      transform: translateY(0);
    } to {
      transform: translateY(var(--hgt));
    }
  }
  &.slide {
    animation: slideUpOut 3s cubic-bezier(1,0, 0, 1) forwards;
  }
  &__title {
    font-size: 1.2rem;
    max-width: 280px;
    z-index: -1;
  }
  &.active {
    animation: fadeOut .3s ease-out forwards;
    @keyframes fadeOut {
      from {
        opacity: 1;
        transform: translateY(0);
      }
      to {
        opacity: 0;
        transform: translateY(-4rem);
      }
    }
  }
  .icon--down {
    border: 1.5px solid $navy-blue;
    border-radius: 50px;
    background-size: 8px;
    height: 22px;
    width: 22px;
    position: relative;
    top: 5px;
    background-position: center;
    margin-left: 8px;
    animation: 3s bounce ease infinite;
    &::after {
      animation: pulse-out 3s ease-out infinite forwards;
      border-radius: 50px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0px 0px 0px 1px #000;
      content: '';
      height: 100%;
      width: 100%;
      z-index: -1;
      transform: scale(1.1);
      transform-origin: center;
    }
  }
  @keyframes bounce {
    0%, 100% {
      background-position-y: 5px;
      transform: scale(1.1);
    } 25% {
      background-position-y: 9px;
      transform: scale(1.2);
    } 50% {}
  }
  @keyframes pulse-out {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0.5;
      box-shadow: 0 0 0 1px rgba($white,0.1), 0 0 10px 10px $white, 0 0 0 10px rgba($white,0.5);
    }
    80% {
      box-shadow: 0 0 0 1px rgba($white,0.1), 0 0 10px 10px $white, 0 0 0 10px rgba($white,0.5);
      transform: scale(2.2);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}