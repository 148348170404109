a,
.link {
  color: currentColor;
  cursor: pointer;
  &:focus-visible {
    border-radius: 2px;
    outline: 2px solid #2f81e1;
  }
  &--outline,
  &--solid {
    border: 1px solid rgba($navy-blue, 0.5);
    border-radius: 25px;
    display: inline-block;
    font-weight: normal;
    padding: 6px 16px 8px;
  }
  &--active,
  &--solid {
    background: $navy-blue;
    color: $white;
  }
  &--has-icon {
    align-items: center;
    display: flex;
    gap: 8px;
  }
  &--dotted {
    padding-bottom: 2px;
    &::after {
      content: '';
      background: radial-gradient(rgba($navy-blue, 0.6) 35%, transparent 24%) repeat-x transparent;
      background-size: 4px 4px;
      display: block;
      height: 5px;
      width: 100%;
    }
    &:hover::after {
      animation: dotsMove 5s linear infinite;
      @keyframes dotsMove {
        from {
          background-position: 0 0;
        }
        to {
          background-position: -100% 0;
        }
      }
    }
  }
  &--tag {
    background: rgba($navy-blue, .04);
    border-radius: 25px;
    color: rgba($navy-blue, .85);
    font-size: .85rem;
    padding: 8px 16px 10px;
    pointer-events: none;
    @include responsive('medium') {
      padding: 10px 16px;
    }
  }
}