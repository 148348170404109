* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #fff;
}

ul {
  list-style-type: none;
}

::placeholder {
  color: #011b3373;
}

input:focus {
  outline: none;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 500;
}

hr {
  height: 1px;
  background: #011b3326;
  border: none;
}

img {
  max-width: 100%;
}

:root {
  font-size: .95rem;
}

@media screen and (width >= 576px) {
  :root {
    font-size: 1rem;
  }
}

html {
  color: #011b33;
  font-family: Graphik, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

h1, h2, h3 {
  font-family: Boing;
}

h1, h2 {
  font-variant-numeric: tabular-nums;
  text-transform: lowercase;
  transform-origin: 0 100%;
  font-size: 2rem;
  transition: transform .4s cubic-bezier(.16, 1, .2, 1);
  transform: scale(1.3333);
}

@media screen and (width >= 768px) {
  h1, h2 {
    font-size: 2.5rem;
  }
}

@media screen and (width >= 992px) {
  h1, h2 {
    font-size: 2.8rem;
  }
}

h3 {
  font-size: 1.6rem;
  line-height: 130%;
}

@media screen and (width >= 992px) {
  h3 {
    font-size: 2.15rem;
  }
}

h4 {
  font-size: 1.1rem;
  font-weight: 500;
}

@media screen and (width >= 992px) {
  h4 {
    font-size: 1.25rem;
  }
}

a, .link {
  text-decoration: none;
}

p {
  font-size: inherit;
  line-height: 170%;
}

small {
  font-size: .7rem;
  line-height: 170%;
}

.sub-header {
  font-size: 1.05rem;
  line-height: 170%;
}

input {
  font-family: inherit;
  font-size: inherit;
}

@font-face {
  font-family: Boing;
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url("Boing-Semibold.31deb07a.woff") format("woff");
}

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-display: block;
  src: url("GraphikRegular.e540935a.woff") format("woff");
}

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url("GraphikMedium.f0b17e9a.woff") format("woff");
}

h1 {
  opacity: 0;
}

h1.show {
  opacity: 1;
}

.loader {
  width: 100vw;
  height: 100vh;
  color: #fff;
  z-index: 5;
  --switch: -1;
  --mult: 1;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

@keyframes slideOff {
  to {
    transform: translateX(calc(100% * var(--mult) * var(--switch)));
  }
}

@keyframes slideDown {
  to {
    color: #011b33;
    transform: translateY(calc(50vh - 110%))scale(1.3333);
  }
}

.loader__slide {
  z-index: 1;
  place-items: center;
  display: grid;
  position: absolute;
  inset: 0;
}

@media screen and (width <= 576px) {
  .loader__slide p {
    font-size: 1rem;
  }
}

.loader__slide:first-of-type {
  --delay: calc(3 * .4s - 1ms);
}

.loader__slide:nth-of-type(2) {
  --delay: calc(2 * .4s - 2ms);
}

.loader__slide:nth-of-type(3) {
  --delay: calc(1 * .4s - 3ms);
}

.loader__slide:nth-of-type(4) {
  --delay: calc(0 * .4s - 4ms);
}

.loader__slide:before {
  content: "";
  background-color: var(--color);
  z-index: -1;
  position: absolute;
  inset: 0;
}

.loader__content {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 32px 24px;
  display: flex;
}

@media screen and (width >= 768px) {
  .loader__content {
    padding: 32px 48px 32px 32px;
  }
}

.loader.active .loader__slide:first-child:before {
  animation: slideOff .6s cubic-bezier(.16, 1, .1, 1) forwards calc(var(--delay)  + 15ms);
}

.loader.active .loader__slide:before {
  animation: slideOff .6s cubic-bezier(.16, 1, .1, 1) forwards var(--delay);
}

.loader.active .loader__slide:not(:first-of-type) h2 {
  --mult: 2;
  animation: slideOff .8s cubic-bezier(.16, 1, .1, 1) forwards calc(var(--delay)  - 50ms);
}

.loader.active .loader__slide:not(:first-of-type) p {
  --mult: 2;
  --switch: 1;
  animation: slideOffLeft .8s cubic-bezier(.16, 1, .1, 1) forwards calc(var(--delay)  - 50ms);
}

@keyframes slideOffLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-50px);
  }
}

.loader.active .loader__slide:first-of-type h2 {
  animation: slideDown .95s cubic-bezier(.16, 1, .1, 1) forwards var(--delay);
}

.container {
  margin: 0 auto;
  padding: 0 24px;
}

@media screen and (width >= 576px) {
  .container {
    padding: 0 32px;
  }
}

.container--columns {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.container--max-width {
  margin: 0 auto;
}

@media screen and (width >= 768px) {
  .container--max-width {
    max-width: 84%;
  }
}

main.container {
  --hgt: 0px;
}

@keyframes slideUpOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(var(--hgt));
  }
}

main.container.slide {
  animation: 3s cubic-bezier(1, 0, 0, 1) forwards slideUpOut;
}

.no-scroll {
  width: 100%;
  position: fixed;
  overflow-y: scroll;
}

.no-scroll header {
  filter: invert();
}

.no-scroll .nav-hex {
  color: #011b33;
}

.hero {
  min-height: 100vh;
  --hgt: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 32px;
  display: flex;
}

.hero.slide {
  animation: 3s cubic-bezier(1, 0, 0, 1) forwards slideUpOut;
}

.hero__title {
  max-width: 280px;
  z-index: -1;
  font-size: 1.2rem;
}

.hero.active {
  animation: .3s ease-out forwards fadeOut;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-4rem);
  }
}

.hero .icon--down {
  height: 22px;
  width: 22px;
  background-position: center;
  background-size: 8px;
  border: 1.5px solid #011b33;
  border-radius: 50px;
  margin-left: 8px;
  animation: 3s infinite bounce;
  position: relative;
  top: 5px;
}

.hero .icon--down:after {
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  transform-origin: center;
  border-radius: 50px;
  animation: 3s ease-out infinite forwards pulse-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  box-shadow: 0 0 0 1px #000;
}

@keyframes bounce {
  0%, 100% {
    background-position-y: 5px;
    transform: scale(1.1);
  }

  25% {
    background-position-y: 9px;
    transform: scale(1.2);
  }
}

@keyframes pulse-out {
  0% {
    opacity: 0;
  }

  25% {
    opacity: .5;
    box-shadow: 0 0 0 1px #ffffff1a, 0 0 10px 10px #fff, 0 0 0 10px #ffffff80;
  }

  80% {
    opacity: 0;
    transform: scale(2.2);
    box-shadow: 0 0 0 1px #ffffff1a, 0 0 10px 10px #fff, 0 0 0 10px #ffffff80;
  }

  100% {
    opacity: 0;
  }
}

.grid {
  --grid-gap: 2.2rem;
  --column-count: 1;
  column-count: var(--column-count);
  column-gap: var(--grid-gap);
  width: 100%;
  padding-bottom: 48px;
  animation: 3s linear slideUp;
}

@media screen and (width >= 576px) {
  .grid {
    --grid-gap: 1.5rem;
    --column-count: 2;
  }
}

@media screen and (width >= 768px) {
  .grid {
    --grid-gap: 2.4rem;
    --column-count: 3;
    padding-bottom: 64px;
  }

  .grid--column-three {
    --column-count: 2;
    padding-bottom: 40px;
  }
}

@media screen and (width >= 1200px) {
  .grid {
    --grid-gap: 2.6rem;
    --column-count: 4;
  }

  .grid--column-three {
    --column-count: 3;
    padding-bottom: 40px;
  }
}

.grid__thumbnail {
  cursor: pointer;
  max-width: 100%;
  width: 100%;
  height: auto;
  transition: all .2s linear;
  display: block;
}

.grid__thumbnail-title {
  color: #fff;
  opacity: 0;
  pointer-events: none;
  text-transform: capitalize;
  width: 100%;
  background: linear-gradient(#0000 10%, #00000085);
  padding: 32px 24px 24px 22px;
  font-weight: 500;
  transition: opacity .25s ease-in;
  position: absolute;
  bottom: 0;
}

.grid__item {
  margin-bottom: var(--grid-gap);
  min-height: 40px;
  animation: .25s cubic-bezier(1, 0, 0, 1) forwards slideInUp;
  display: block;
}

@keyframes slideUp {
  from {
    transform: translate3d(0, 30%, 0);
  }
}

.grid--hide-items > .grid__item {
  display: none;
}

.grid--hide-items > .show {
  display: -webkit-inline-box;
}

.image {
  color: inherit;
  will-change: transform;
  transition: transform .5s cubic-bezier(.16, 1, .2, 1), opacity .2s linear;
  position: relative;
}

.image:hover {
  transform: scale(1.06);
  box-shadow: 0 0 0 10px;
}

.image:hover .grid__thumbnail-title {
  opacity: 1;
}

@keyframes slideInUp {
  from {
    transform: translateY(500px);
  }

  to {
    transform: translate(0);
  }
}

.header {
  color: #fff;
  mix-blend-mode: difference;
  z-index: 1;
  padding: 22px 0;
  font-weight: 500;
  position: sticky;
  top: 0;
}

.header a:focus-visible {
  outline: 2px solid #d27e00;
}

@media screen and (width >= 576px) {
  .header {
    padding: 28px 0;
  }
}

.nav {
  pointer-events: none;
  width: 100%;
  z-index: 1;
  padding: 18px 0;
  position: fixed;
  bottom: 0;
}

@media screen and (width >= 576px) {
  .nav {
    padding: 28px 0;
  }
}

.nav.active:before, .nav.active label, .nav.active .nav__item:last-child, .nav.active .nav__item:first-child {
  transform: none;
}

.nav.active .nav__item:last-child {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  row-gap: 8px;
  display: flex;
}

.nav:before {
  content: "";
  z-index: -1;
  transition: transform .8s cubic-bezier(.16, 1, .1, 1);
  position: absolute;
  inset: 0;
  transform: translateY(100%);
}

.nav__item:not(ul) {
  will-change: transform;
  flex-grow: 1;
  flex-basis: 0;
}

.nav__item:first-child {
  transform-origin: 0 100%;
  transition: transform .4s cubic-bezier(.16, 1, .2, 1);
  transform: scale(1.3333);
}

.nav__item:last-child {
  transition: transform .6s cubic-bezier(.16, 1, .2, 1) .4s;
  transform: translateY(300%);
}

.nav__filter-categories {
  column-gap: 12px;
  display: flex;
}

.nav__filter-categories::-webkit-scrollbar {
  display: none;
}

@media screen and (width >= 768px) {
  .nav__filter-categories {
    max-width: none;
  }
}

.nav__category + label {
  --delay: 25ms;
  cursor: pointer;
  will-change: transform;
  pointer-events: auto;
  background-color: #fff;
  border: 1px solid #011b33;
  border-radius: 13px;
  padding: 8px 20px;
  font-size: .9rem;
  transition: transform .6s cubic-bezier(.16, 1, .2, 1);
  display: block;
  transform: translateY(200%)translateX(20px);
}

@media screen and (width >= 576px) {
  .nav__category + label {
    font-size: inherit;
  }
}

.nav__category + label:hover, .nav__category + label:focus {
  transition-delay: 0;
  transition-property: transform;
  transform: scale(1.08);
}

.nav__category:checked + label {
  color: #fff;
  transition-delay: calc(var(--delay) * 1);
  background-color: #011b33;
  transition-property: transform;
}

.nav__category:checked + label:nth-child(3n+1) {
  --color: #22d34b;
  background-color: var(--color);
  border-color: var(--color);
}

.nav__category:checked + label:nth-child(3n+2) {
  --color: #fa2;
  background-color: var(--color);
  border-color: var(--color);
}

.nav__category:checked + label:nth-child(3n+3) {
  --color: #00c3f7;
  background-color: var(--color);
  border-color: var(--color);
}

.nav__category:checked + label:first-of-type {
  --color: #011b33;
  background-color: var(--color);
  border-color: var(--color);
}

.nav__category:not(:checked) + label {
  border-color: var(--color);
  position: relative;
  overflow: hidden;
}

.nav__category:not(:checked) + label:nth-child(3n+1), .nav__category:not(:checked) + label:nth-child(3n+1):hover {
  --color: #22d34b;
  border-color: var(--color);
  transition-delay: calc(var(--delay) * 2);
  transition-property: transform;
}

.nav__category:not(:checked) + label:nth-child(3n+1):after, .nav__category:not(:checked) + label:nth-child(3n+1):hover:after {
  background-color: var(--color);
}

.nav__category:not(:checked) + label:nth-child(3n+2), .nav__category:not(:checked) + label:nth-child(3n+2):hover {
  --color: #fa2;
  border-color: var(--color);
  transition-delay: calc(var(--delay) * 4);
  transition-property: transform;
}

.nav__category:not(:checked) + label:nth-child(3n+2):after, .nav__category:not(:checked) + label:nth-child(3n+2):hover:after {
  background-color: var(--color);
}

.nav__category:not(:checked) + label:nth-child(3n+3), .nav__category:not(:checked) + label:nth-child(3n+3):hover {
  --color: #00c3f7;
  border-color: var(--color);
  transition-delay: calc(var(--delay) * 3);
  transition-property: transform;
}

.nav__category:not(:checked) + label:nth-child(3n+3):after, .nav__category:not(:checked) + label:nth-child(3n+3):hover:after {
  background-color: var(--color);
}

.nav__category:not(:checked) + label:first-of-type, .nav__category:not(:checked) + label:first-of-type:hover {
  --color: #011b33;
  border-color: var(--color);
  transition-delay: calc(var(--delay) * 2);
  transition-property: transform;
}

.nav__category:not(:checked) + label:first-of-type:after, .nav__category:not(:checked) + label:first-of-type:hover:after {
  background-color: var(--color);
}

.nav__category:not(:checked) + label:before, .nav__category:not(:checked) + label:after {
  content: "";
  z-index: -1;
  transition: transform .4s cubic-bezier(.16, 1, .2, 1);
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.nav__category:not(:checked) + label:before {
  background-color: var(--color);
  opacity: .5;
  transform: translateX(100%);
}

.nav__category:not(:checked) + label:after {
  border-radius: inherit;
  background-color: #011b33;
  transition-delay: 50ms;
}

.nav__category:not(:checked) + label:hover, .nav__category:not(:checked) + label:focus {
  color: #fff;
  border-color: #011b33;
}

.nav__category:not(:checked) + label:hover:before, .nav__category:not(:checked) + label:hover:after, .nav__category:not(:checked) + label:focus:before, .nav__category:not(:checked) + label:focus:after {
  transform: none;
}

.nav__hex-code {
  font-variant-numeric: tabular-nums;
  pointer-events: auto;
  align-self: flex-end;
}

.nav-hex {
  color: #fff;
  mix-blend-mode: difference;
  z-index: 2;
  padding: 28px 0;
  display: flex;
  position: fixed;
  bottom: 0;
}

.nav-hex.active, .nav-hex.active .hex__item:first-child {
  transform: none;
}

.nav-hex .hex__item {
  will-change: transform;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-hex .hex__item:first-child {
  transform-origin: 0 100%;
  transition: transform .4s cubic-bezier(.16, 1, .2, 1);
  transform: scale(1.3333);
}

.offcanvas-sidebar {
  height: 100vh;
  width: 90vw;
  z-index: 3;
  background: #f9fafb;
  padding: 22px 32px;
  transition: transform .2s cubic-bezier(.5, 0, 0, 1);
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100vw);
}

@media screen and (width >= 576px) {
  .offcanvas-sidebar {
    padding: 28px 32px;
  }
}

.offcanvas-sidebar--right {
  flex-direction: column;
  gap: 32px;
  display: flex;
  left: auto;
  right: 0;
  transform: translateX(100vw);
}

@media screen and (width >= 576px) {
  .offcanvas-sidebar {
    width: 24.5rem;
  }
}

.offcanvas-sidebar__header {
  font-weight: 500;
  display: flex;
  position: relative;
}

.offcanvas-sidebar__about {
  padding-top: 116px;
  font-size: 1.2em;
}

.offcanvas-sidebar__about b {
  font-family: Boing;
}

.offcanvas-sidebar__footer {
  padding: inherit;
  width: inherit;
  flex-direction: column;
  row-gap: 24px;
  font-weight: 500;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.offcanvas-sidebar__logo {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-sidebar__search {
  width: 100%;
  background: none;
  border-width: 0 0 1px;
  border-radius: 0;
  padding: 0 32px 16px 0;
  font-weight: 500;
}

.offcanvas-sidebar__search + .icon {
  position: absolute;
  right: 0;
}

.offcanvas-sidebar__grid {
  padding-top: 18px;
  display: block;
}

.offcanvas-sidebar__grid--swatch .color {
  cursor: pointer;
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 0 1px #0000000d;
}

@media screen and (width >= 768px) {
  .offcanvas-sidebar__grid--swatch .color {
    height: 56px;
    width: 56px;
  }
}

.offcanvas-sidebar__grid--swatch .color .checkmark {
  pointer-events: none;
  stroke-dasharray: 40;
  stroke-dashoffset: 50px;
  height: 100%;
  width: 100%;
  display: none;
  position: absolute;
  transform: scale(.3);
}

.offcanvas-sidebar__grid--swatch .color.active {
  transition: all .2s ease-in;
  animation: .25s cubic-bezier(1, 0, 0, 1) forwards spring;
  transform: scale(.9);
}

@keyframes spring {
  to {
    transform: scale(1);
  }
}

.offcanvas-sidebar__grid--swatch .color.active .checkmark {
  animation: .2s linear forwards tick;
  display: block;
}

@keyframes tick {
  to {
    stroke-dasharray: 24;
  }
}

.offcanvas-sidebar__grid--swatch .color.active:before {
  content: "";
  height: 100%;
  transform-origin: center;
  width: 100%;
  background-position: center;
  background-size: contain;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.29);
}

.offcanvas-sidebar__grid--swatch .color.active:hover:before {
  background-position: center;
  background-size: contain;
  border-radius: 50px;
}

.offcanvas-sidebar__grid .tooltip {
  color: #fff;
  z-index: 1;
  background: #413d4b;
  border-radius: 6px;
  padding: 6px 10px;
  font-size: .7rem;
  display: inline-block;
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%);
}

.offcanvas-sidebar__grid .tooltip:after {
  content: "";
  height: 0;
  width: 0;
  border: 6px solid #0000;
  border-top-color: #413d4b;
  border-bottom: none;
  position: absolute;
  bottom: -12%;
  left: 50%;
  transform: translate(-50%);
}

.offcanvas-sidebar__grid > li {
  padding: 6px 8px 4px 0;
  display: inline-block;
}

.offcanvas-sidebar.show {
  transform: translateX(0);
}

.offcanvas-backdrop {
  height: 100%;
  visibility: hidden;
  width: 100%;
  z-index: 2;
  background: #ffffff80;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.show {
  visibility: visible;
}

.overlay {
  min-height: 100vh;
  width: 100%;
  z-index: 3;
  background: #fff;
  transition-delay: 0s;
  position: fixed;
  top: 0;
  bottom: 0;
  transform: translateY(100vh);
}

.overlay.profile {
  z-index: 2;
}

.overlay-loader {
  height: 100%;
  width: 100%;
  z-index: 20;
  background: #f9fafb;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

.overlay-loader.loading {
  animation: .5s cubic-bezier(.65, .005, .35, .995) forwards slideUp;
  display: block;
}

@keyframes slideUp {
  to {
    transform: translateY(0);
  }
}

.overlay-loader.loaded {
  animation: .5s cubic-bezier(.65, .005, .35, .995) forwards slideOut;
  display: block;
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.overlay.active {
  overflow-y: auto;
  transform: translateY(0);
}

.overlay.focused {
  z-index: 9999;
  justify-content: center;
  align-items: center;
  padding: 24px;
  display: flex;
}

@media screen and (width >= 768px) {
  .overlay.focused {
    padding: 32px;
  }
}

@media screen and (width >= 992px) {
  .overlay.focused {
    padding: 32px 104px;
  }
}

.overlay.focused figure {
  cursor: zoom-out;
  height: 100%;
  max-height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.overlay.focused .gallery__ctrl {
  display: none;
}

.overlay.focused .overlay__close-button {
  background-color: #fff;
  position: absolute;
  top: 24px;
  right: 24px;
}

@media screen and (width >= 768px) {
  .overlay.focused .overlay__close-button {
    top: 28px;
    right: 48px;
  }
}

.overlay__header {
  width: 100%;
  z-index: 1;
  padding: 16px 0;
  position: sticky;
  top: 0;
}

@media screen and (width >= 992px) {
  .overlay__header {
    padding: 28px 0 8px;
  }
}

.overlay__close-button {
  cursor: pointer;
  height: 40px;
  transform-origin: 100%;
  background: none;
  border: none;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  transition: all .1s ease-in;
  display: flex;
  transform: scale(.75);
}

.overlay__close-button:after {
  content: url("cancel.699ef4b1.svg");
}

@media screen and (width >= 992px) {
  .overlay__close-button {
    transform: scale(1);
  }
}

.overlay__project-information .main {
  grid-template-columns: 1fr;
  column-gap: 152px;
  padding: 48px 0 56px;
  display: grid;
}

@media screen and (width >= 992px) {
  .overlay__project-information .main {
    grid-template-columns: .6fr 1fr;
    padding: 96px 0 72px;
  }
}

.overlay__project-information .main .sub-header {
  padding: 20px 0;
}

@media screen and (width >= 992px) {
  .overlay__project-information .main .sub-header {
    padding: 20px 0 40px;
  }
}

.overlay__project-information .main .tags {
  text-transform: capitalize;
  flex-wrap: wrap;
  gap: 14px;
  display: flex;
}

.overlay__project-details {
  padding-top: 48px;
  font-size: .906rem;
}

@media screen and (width >= 992px) {
  .overlay__project-details {
    padding-top: 0;
  }
}

.overlay__project-details li {
  border-bottom: 1px solid #011b331a;
  justify-content: space-between;
  padding: 20px 0;
  display: flex;
}

.overlay__project-details li:first-child {
  padding-top: 10px;
}

@media screen and (width >= 992px) {
  .overlay__project-details li {
    padding: 24px 0;
  }
}

.overlay__project-details span {
  text-transform: capitalize;
  flex-wrap: wrap;
  gap: 16px;
  font-size: .95rem;
  display: flex;
}

.overlay__project-details span.label {
  padding-right: 40px;
  font-weight: 500;
}

.overlay__project-details span:not(.label) {
  justify-content: flex-end;
}

.overlay__wrap {
  background: linear-gradient(to top, #eeeeee6e 7%, #0000 11%);
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
}

@media screen and (width >= 768px) {
  .overlay__wrap {
    margin-bottom: 64px;
  }
}

.overlay__related-projects h4 {
  margin-bottom: 32px;
}

.overlay__related-projects .grid {
  --column-count: 1;
  column-count: auto;
  grid-template-columns: repeat(var(--column-count), 1fr);
  padding-bottom: 40px;
  display: grid;
}

@media screen and (width >= 768px) {
  .overlay__related-projects .grid {
    --column-count: 5;
    grid-template-columns: repeat(var(--column-count), 1fr);
    padding-bottom: 72px;
  }
}

.gallery {
  flex-direction: column;
  gap: 24px 32px;
  display: flex;
}

@media screen and (width >= 768px) {
  .gallery {
    row-gap: 32px;
  }
}

.gallery__thumbnails {
  max-width: calc(100vw - 64px);
  scrollbar-width: none;
  white-space: nowrap;
  margin: 0 auto;
  padding: 2px;
  display: inline-block;
  overflow-x: auto;
}

.gallery__thumbnails::-webkit-scrollbar {
  display: none;
}

@media screen and (width >= 768px) {
  .gallery__thumbnails {
    max-width: 580px;
  }
}

.gallery__thumbnail-item {
  --dimension: 40px;
  cursor: pointer;
  height: var(--dimension);
  width: var(--dimension);
  background: #f9fafb;
  border-radius: 4px;
  margin-left: 18px;
  display: inline-block;
  overflow: hidden;
}

.gallery__thumbnail-item.active {
  box-shadow: 0 0 0 1px #011b33;
}

.gallery__thumbnail-item:first-of-type {
  margin-left: 0;
}

.gallery__thumbnail-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
  background: #011b330a;
}

.gallery__viewer {
  height: auto;
  background: url("loader.444352b9.gif") center / 32px no-repeat;
  align-items: center;
  display: flex;
}

@media screen and (width >= 768px) {
  .gallery__viewer {
    height: 420px;
  }
}

@media screen and (width >= 992px) {
  .gallery__viewer {
    height: 32vw;
  }
}

.gallery__viewer figure {
  cursor: zoom-in;
  height: inherit;
  border-radius: 4px;
  justify-content: center;
  margin: 0 auto;
  display: none;
  overflow: hidden;
}

.gallery__viewer figure.active {
  display: inherit;
}

.gallery__viewer video {
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: none;
}

.gallery__viewer video.active {
  display: inherit;
}

.gallery__viewer-image {
  object-fit: contain;
  max-height: inherit;
  pointer-events: none;
  transition: transform .5s ease-out;
}

.gallery__thumbnail-ctrl {
  cursor: pointer;
  z-index: 1;
  background: #ffffffd9;
  border: 1px solid;
  border-radius: 4px;
  margin-top: -2px;
  padding: 4px;
  position: absolute;
  left: -5px;
}

.gallery__thumbnail-ctrl.hide {
  visibility: hidden;
}

.gallery__thumbnail-ctrl:before {
  content: "";
  height: 16px;
  width: 16px;
  background: url("chevron-left.12e3444f.svg") center / contain no-repeat;
  display: block;
}

.gallery__thumbnail-ctrl.right {
  left: auto;
  right: -5px;
}

.gallery__thumbnail-ctrl.right:before {
  content: "";
  background-image: url("chevron-right.07185a07.svg");
}

.thumbnails-wrapper {
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.profile--grid {
  column-gap: 48px;
  padding: 0 0 60px 24px;
  display: grid;
}

@media screen and (width >= 992px) {
  .profile--grid {
    grid-template-columns: .45fr 1fr;
    padding: 0 24px 60px;
  }
}

@media screen and (width >= 1200px) {
  .profile--grid {
    grid-template-columns: .35fr 1fr;
  }
}

.profile__about {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.profile__about + div {
  padding-top: 32px;
}

@media screen and (width >= 768px) {
  .profile__about {
    border-right: 1px solid #011b3326;
    padding-top: 32px;
    padding-right: 40px;
  }
}

.profile__name {
  text-transform: capitalize;
  padding: 20px 0 0;
}

.profile__name + a {
  margin: 18px 0 0;
  font-size: .95rem;
  font-weight: 500;
  display: inline-block;
}

.profile__role {
  letter-spacing: .025rem;
  text-transform: uppercase;
  padding-bottom: 30px;
  font-weight: 500;
  display: block;
}

.profile__thumbnail {
  --dimension: 64px;
  height: var(--dimension);
  object-fit: cover;
  width: var(--dimension);
  border-radius: 8px;
}

@media screen and (width >= 768px) {
  .profile__thumbnail {
    --dimension: 96px;
  }
}

.profile__info {
  word-break: break-word;
}

.profile__info p {
  color: #011b33cc;
  margin-top: 18px;
}

a, .link {
  color: currentColor;
  cursor: pointer;
}

a:focus-visible, .link:focus-visible {
  border-radius: 2px;
  outline: 2px solid #2f81e1;
}

a--outline, a--solid, .link--outline, .link--solid {
  border: 1px solid #011b3380;
  border-radius: 25px;
  padding: 6px 16px 8px;
  font-weight: normal;
  display: inline-block;
}

a--active, a--solid, .link--active, .link--solid {
  color: #fff;
  background: #011b33;
}

a--has-icon, .link--has-icon {
  align-items: center;
  gap: 8px;
  display: flex;
}

a--dotted, .link--dotted {
  padding-bottom: 2px;
}

a--dotted:after, .link--dotted:after {
  content: "";
  height: 5px;
  width: 100%;
  background: radial-gradient(#011b3399 35%, #0000 24%) 0 0 / 4px 4px repeat-x;
  display: block;
}

a--dotted:hover:after, .link--dotted:hover:after {
  animation: 5s linear infinite dotsMove;
}

@keyframes dotsMove {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -100% 0;
  }
}

a--tag, .link--tag {
  color: #011b33d9;
  pointer-events: none;
  background: #011b330a;
  border-radius: 25px;
  padding: 8px 16px 10px;
  font-size: .85rem;
}

@media screen and (width >= 768px) {
  a--tag, .link--tag {
    padding: 10px 16px;
  }
}

.icon {
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.icon--down {
  background-image: url("arrow-down.d76f1e9f.svg");
}

.icon--search {
  background-image: url("search.0c3359d7.svg");
}

.icon--search-white {
  background-image: url("search--white.076db242.svg");
}

.icon--slider {
  background-image: url("slider.70e007d7.svg");
}

.icon--arrow-up-right {
  background-image: url("arrow-up-right.bee72931.svg");
}

/*# sourceMappingURL=index.fd92dea8.css.map */
