$easeOutExpo: cubic-bezier(0.16, 1, 0.2, 1);

.grid {
  --grid-gap: 2.2rem;
  --column-count: 1;
  animation: slideUp 3s linear;
  column-count: var(--column-count);
  column-gap: var(--grid-gap);
  padding-bottom: 48px;
  width: 100%;
  @include responsive('small') {
    --grid-gap: 1.5rem;
    --column-count: 2;
  }
  @include responsive('medium') {
    --grid-gap: 2.4rem;
    --column-count: 3;
    padding-bottom: 64px;
    &--column-three {
      --column-count: 2;
      padding-bottom: 40px;
    }
  }
  @include responsive('x-large') {
    --grid-gap: 2.6rem;
    --column-count: 4;
    &--column-three {
      --column-count: 3;
      padding-bottom: 40px;
    }
  }
  &__thumbnail {
    display: block;
    cursor: pointer;
    max-width: 100%;
    transition: 0.2s linear;
    width: 100%;
    height: auto;
  }
  &__thumbnail-title {
    background: linear-gradient(to bottom, transparent 10%, rgba(#000, .52));
    bottom: 0;
    color: $white;
    font-weight: 500;
    opacity: 0;
    padding: 32px 24px 24px 22px;
    pointer-events: none;
    position: absolute;
    text-transform: capitalize;
    transition: .25s ease-in opacity;
    width: 100%;
  }
  &__item {
    animation: slideInUp .25s cubic-bezier(1,0,0,1) forwards;
    display: block;
    margin-bottom: var(--grid-gap);
    min-height: 40px;
    @keyframes slideUp{
      from {
        transform: translate3d(0, 30%, 0);
      }
    }
  }
  &--hide-items > &__item {
    display: none;
  }
  &--hide-items > .show {
    display: -webkit-inline-box;
  }
}

.image {
  color: inherit;
  // border: 10px solid transparent;
  position: relative;
  transition: transform .5s $easeOutExpo, opacity .2s linear;
  will-change: transform;

  &:hover {
    box-shadow: 0 0 0 10px currentColor;
    transform: scale(1.06);
    & .grid__thumbnail-title {
      opacity: 1;
    }
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(500px);
  }
  to {
    transform: translate(0);
  }
}