.offcanvas-sidebar {
  background: $near-white;
  height: 100vh;
  left: 0;
  padding: 22px 32px;
  position: fixed;
  top: 0;
  transform: translateX(-100vw);
  transition: .2s cubic-bezier(0.5,0,0,1) transform;
  width: 90vw;
  z-index: 3;
  @include responsive('small') {
    padding: 28px 32px;
  }
  &--right {
    display: flex;
    flex-direction: column;
    gap: 32px;
    left: auto;
    right: 0;
    transform: translateX(100vw);
  }
  @include responsive('small') {
    width: 24.5rem;
  }
  &__header {
    display: flex;
    font-weight: 500;
    position: relative;
  }
  &__about {
    font-size: 1.2em;
    padding-top: 116px;
    & b {
      font-family: $secondary-font;
    }
  }
  &__footer {
    bottom: 0;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    left: 0;
    padding: inherit;
    position: absolute;
    row-gap: 24px;
    width: inherit;
  }
  &__logo {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  &__search {
    background: transparent;
    border-radius: 0;
    border-width: 0 0 1px;
    font-weight: 500;
    padding: 0 32px 16px 0;
    width: 100%;
    & + .icon {
      position: absolute;
      right: 0;
    }
  }
  &__grid {
    display: block;
    padding-top: 18px;
    &--swatch .color {
      background: $white;
      box-shadow: 0 0 0px 1px rgba($black, 0.05);
      border-radius: 50px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      height: 40px;
      width: 40px;
      @include responsive('medium') {
        height: 56px;
        width: 56px;
      }
      .checkmark {
        display: none;
        pointer-events: none;
        position: absolute;
        stroke-dasharray: 40px;
        stroke-dashoffset: 50px;
        height: 100%;
        transform: scale(0.3);
        width: 100%;
      }
      &.active {
        animation: spring .25s cubic-bezier(1,0,0,1) forwards;
        transform: scale(0.9);
        transition: .2s ease-in all;
        @keyframes spring {
          to {
            transform: scale(1);
          }
        }
        .checkmark {
          animation: tick .2s linear forwards;
          display: block;
          @keyframes tick {
            to {
              stroke-dasharray: 24px;
            }
          }
        }
      }
      &.active::before {
        // background: url('/assets/images/icons/check.svg') no-repeat transparent;
        background-position: center;
        background-size: contain;
        border-radius: 50px;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        transform: scale(.29);
        transform-origin: center;
        top: 0;
        width: 100%;
      }
      &.active:hover::before {
        background-position: center;
        background-size: contain;
        border-radius: 50px;
      }
    }
    & .tooltip {
      background: #413d4b;
      color: white;
      display: inline-block;
      position: absolute;
      border-radius: 6px;
      font-size: .7rem;
      padding: 6px 10px;
      top: -50%; left: 50%;
      transform: translate(-50%, 0);
      z-index: 1;
      &::after {
        content: '';
        border: 6px solid #413d4b;
        border-color: #413d4b transparent transparent transparent;
        border-bottom: none;
        height: 0;
        width: 0;
        position: absolute;
        left: 50%;
        bottom: -12%;
        transform: translate(-50%, 0);
      }
    }
  }
  &__grid > li {
    display: inline-block;
    padding: 6px 8px 4px 0;
  }
  &.show {
    transform: translateX(0);
  }
}

.offcanvas-backdrop {
  background: rgba($white, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 2;
  &.show {
    visibility: visible;
  }
}