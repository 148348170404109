$easeOutExpo: cubic-bezier(0.16, 1, 0.1, 1);

h1 {
  opacity: 0;
  &.show {
    opacity: 1;
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: #fff;
  z-index: 5;
  --switch: -1;
  --mult: 1;
  @keyframes slideOff {
    to {
      transform: translateX(calc((100% * var(--mult)) * var(--switch)));
    }
  }
  @keyframes slideDown {
    to {
      transform: translateY(calc(50vh - 110%)) scale(1.3333);
      color: #011b33;
    }
  }

  &__slide {
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
    z-index: 1;

    p {
      @include responsive('x-small') {
        font-size: 1rem;
      }
    }

    @for $i from 1 through 4 {
      &:nth-of-type(#{$i}) {
        --delay: calc(#{4 - $i} * 0.4s - #{$i}ms);
      }
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: var(--color);
      z-index: -1;
    }
  }

  &__content {
    padding: 32px 24px 32px 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include responsive('medium') {
      padding: 32px 48px 32px 32px;
    }
  }

  &.active {
    .loader {
      &__slide {
        &:first-child::before {
          animation: slideOff .6s $easeOutExpo forwards calc(var(--delay) + 15ms);
        }
        &::before {
          animation: slideOff .6s $easeOutExpo forwards var(--delay);
        }
        &:not(:first-of-type) {
          h2 {
            --mult: 2;
            animation: slideOff .8s $easeOutExpo forwards calc(var(--delay) - 50ms);
          }
          p {
            --mult: 2;
            --switch: 1;
            animation: slideOffLeft .8s $easeOutExpo forwards calc(var(--delay) - 50ms);
            @keyframes slideOffLeft {
              from {
                transform: translateX(0);
                opacity: 1;
              }
              to {
                opacity: 0;
                transform: translateX(-50px);
              }
            }
          }
        }
        &:first-of-type {
          h2 {
            animation: slideDown .95s $easeOutExpo forwards var(--delay);
          }
        }
      }
    }
  }
}