$x-small: 576px;
$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;

@mixin responsive($size) {
  @if $size == 'x-small' {
    @media screen and (max-width: $x-small) {
      @content;
    }
  }
  @else if $size == 'small' {
    @media screen and (min-width: $small) {
      @content;
    }
  }
  @if $size == 'medium' {
    @media screen and (min-width: $medium) {
      @content;
    }
  }
  @else if $size == 'large' {
    @media screen and (min-width: $large) {
      @content;
    }
  }
  @else if $size == 'x-large' {
    @media screen and (min-width: $x-large) {
      @content;
    }
  }
}