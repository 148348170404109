@font-face {
  font-family: 'Boing';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url('../fonts/Boing-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: normal;
  font-display: block;
  src: url('../fonts/GraphikRegular.woff') format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url('../fonts/GraphikMedium.woff') format('woff');
}