.nav {
  bottom: 0;
  padding: 18px 0;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 1;
  @include responsive('small'){
    padding: 28px 0;
  }
  &.active {
    &::before {
      transform: none;
    }
    label {
      transform: none;
    }
    .nav__item:last-child,
    .nav__item:first-child {
      transform: none;
    }
    .nav__item:last-child {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      row-gap: 8px;
    }
  }
  // nav bg
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    transform: translateY(100%);
    transition: transform .8s cubic-bezier(0.16, 1, 0.1, 1);
  }
  &__item {
    &:not(ul) {
      flex-grow: 1;
      flex-basis: 0;
      will-change: transform;
    }
    &:first-child {
      transform: scale(1.3333);
      transition: transform .4s $easeOutExpo;
      transform-origin: bottom left;
    }
    &:last-child {
      transform: translateY(300%);
      transition: transform .6s .4s $easeOutExpo;
    }
  }
  &__filter-categories {
    display: flex;
    column-gap: 12px;
    &::-webkit-scrollbar {
      display: none;
    }
    @include responsive('medium') {
      max-width: none;
    }
  }
  &__category {
    & + label {
      background-color: $white;
      --delay: .025s;
      display: block;
      cursor: pointer;
      font-size: .9rem;
      padding: 8px 20px;
      border: 1px solid #011b33;
      border-radius: 13px;
      transition: transform .6s $easeOutExpo;
      will-change: transform;
      transform: translateY(200%) translateX(20px);
      pointer-events: auto;

      @include responsive('small') {
        font-size: inherit;
      }

      &:hover,
      &:focus {
        transform: scale(1.08);
        transition-delay: 0;
        transition-property: transform;
      }
    }
    &:checked {
      & + label {
        background-color: #011b33;
        color: #fff;
        transition-delay: calc(var(--delay) * 1);
        transition-property: transform;
        &:nth-child(3n + 1) {
          --color: #22D34B; // green
          background-color: var(--color);
          border-color: var(--color);
        }
        &:nth-child(3n + 2) {
          --color: #FFAA22; // orange
          background-color: var(--color);
          border-color: var(--color);
        }
        &:nth-child(3n + 3) {
          --color: #00C3F7; // blue
          background-color: var(--color);
          border-color: var(--color);
        }
        &:first-of-type {
          --color: #011b33; // blue
          background-color: var(--color);
          border-color: var(--color);
        }
      }
    }
    &:not(:checked) {
      & + label {
        position: relative;
        overflow: hidden;
        border-color: var(--color);

        &:nth-child(3n + 1),
        &:nth-child(3n + 1):hover {
          --color: #22D34B; // green
          border-color: var(--color);
          transition-delay: calc(var(--delay) * 2);
          transition-property: transform;
          &::after {
            background-color: var(--color);
          }
        }
        &:nth-child(3n + 2),
        &:nth-child(3n + 2):hover {
          --color: #FFAA22; // orange
          border-color: var(--color);
          transition-delay: calc(var(--delay) * 4);
          transition-property: transform;
          &::after {
            background-color: var(--color);
          }
        }
        &:nth-child(3n + 3),
        &:nth-child(3n + 3):hover {
          --color: #00C3F7; // blue
          border-color: var(--color);
          transition-delay: calc(var(--delay) * 3);
          transition-property: transform;
          &::after {
            background-color: var(--color);
          }
        }
        &:first-of-type,
        &:first-of-type:hover {
          --color: #011b33; // green
          border-color: var(--color);
          transition-delay: calc(var(--delay) * 2);
          transition-property: transform;
          &::after {
            background-color: var(--color);
          }
        }

        //label button effects
        &::before,
        &::after {
          content: '';
          position: absolute;
          inset: 0;
          z-index: -1;
          transform: translateX(-100%);
          transition: transform .4s $easeOutExpo;
        }
        &::before {
          transform: translateX(100%);
          background-color: var(--color);
          opacity: .5;
        }
        &::after {
          background-color: #011b33;
          border-radius: inherit;
          transition-delay: .05s;
        }

        &:hover,
        &:focus {
          border-color: #011b33;
          color: #fff;
          &::before,
          &::after {
            transform: none;
          }
        }
      }
    }
  }
  &__hex-code {
    align-self: flex-end;
    font-variant-numeric: tabular-nums;
    pointer-events: auto;
  }
}

.nav-hex {
  color: $white;
  bottom: 0;
  display: flex;
  mix-blend-mode: difference;
  padding: 28px 0;
  position: fixed;
  z-index: 2;
  // .nav__hex-code.show {
  //   transform: none;
  // }
  &.active {
    transform: none;
    .hex__item:first-child {
      transform: none;
    }
  }
  .hex__item {
    flex-grow: 1;
    flex-basis: 0;
    will-change: transform;
    &:first-child {
      transform: scale(1.3333);
      transition: transform .4s $easeOutExpo;
      transform-origin: bottom left;
    }
  }
}