.gallery {
  column-gap: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  @include responsive('medium') {
    row-gap: 32px;
  }
  &__thumbnails {
    display: inline-block;
    max-width: calc(100vw - 64px);
    margin: 0 auto;
    overflow-x: auto;
    padding: 2px;
    scrollbar-width: none;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    @include responsive('medium') {
      max-width: 580px;
    }
  }
  &__thumbnail-item {
    --dimension: 40px;
    background: $near-white;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    height: var(--dimension);
    margin-left: 18px;
    overflow: hidden;
    width: var(--dimension);
    &.active {
      box-shadow: 0 0 0px 1px $navy-blue;
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
  &__thumbnail-image {
    background: rgba($navy-blue, .04);
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  &__viewer {
    background: url('../images/loader.gif') no-repeat transparent;
    background-size: 32px;
    background-position: center;
    align-items: center;
    display: flex;
    height: auto;
    @include responsive('medium') {
      height: 420px;
    }
    @include responsive('large') {
      height: 32vw;
    }
    figure {
      border-radius: 4px;
      cursor: zoom-in;
      display: none;
      height: inherit;
      justify-content: center;
      margin: 0 auto;
      overflow: hidden;
      &.active {
        display: inherit;
      }
    }
    video {
      display: none;
      margin: 0 auto;
      max-height: 100%;
      max-width: 100%;
      &.active {
        display: inherit;
      }
    }
  }
  &__viewer-image {
    object-fit: contain;
    max-height: inherit;
    pointer-events: none;
    transition: transform .5s ease-out;
  }
  &__thumbnail-ctrl {
    background: rgba($white, .85);
    border: 1px solid;
    border-radius: 4px;
    cursor: pointer;
    left: -5px;
    margin-top: -2px;
    padding: 4px;
    position: absolute;
    z-index: 1;
    &.hide {
      visibility: hidden;
    }
    &::before {
      content: '';
      background: url('../images/icons/chevron-left.svg') no-repeat transparent center;
      background-size: contain;
      display: block;
      height: 16px;
      width: 16px;
    }
    &.right {
      &::before {
        content: '';
        background-image: url('../images/icons/chevron-right.svg');
      }
      left: auto;
      right: -5px;
    }
  }
}

.thumbnails-wrapper {
  align-items: center;
  display: flex;
  margin: 0 auto;
  position: relative;
}