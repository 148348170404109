.container {
  margin: 0 auto;
  padding: 0 24px;
  @include responsive('small') {
    padding: 0 32px;
  }
  &--columns {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  &--max-width {
    margin: 0 auto;
    @include responsive('medium') {
      max-width: 84%;
    }
  }
  @at-root main#{&} {
    --hgt: 0px;
    @keyframes slideUpOut {
      from {
        transform: translateY(0);
      } to {
        transform: translateY(var(--hgt));
      }
    }
    &.slide {
      animation: slideUpOut 3s cubic-bezier(1,0, 0, 1) forwards;
    }
  }
}

.no-scroll {
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  header {
    filter: invert(1);
  }
  .nav-hex {
    color: $navy-blue;
  }
}