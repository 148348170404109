.overlay {
  background: $white;
  bottom: 0;
  min-height: 100vh;
  position: fixed;
  top: 0;
  transform: translateY(100vh);
  transition-delay: 0s;
  width: 100%;
  z-index: 3;
  &.profile {
    z-index: 2;
  }
  &-loader {
    background: $near-white;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    transform: translateY(100%);
    top: 0;
    width: 100%;
    z-index: 20;
    &.loading {
      animation: slideUp .5s cubic-bezier(.65,.005,.35,.995) forwards;
      display: block;
      @keyframes slideUp {
        to {
          transform: translateY(0);
        }
      }
    }
    &.loaded {
      animation: slideOut .5s cubic-bezier(.65,.005,.35,.995) forwards;
      display: block;
      @keyframes slideOut {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(-100%);
        }
      }
    }
  }
  &.active {
    overflow-y: auto;
    transform: translateY(0);
  }
  &.focused {
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    @include responsive('medium') {
      padding: 32px;
    }
    @include responsive('large') {
      padding: 32px 104px;
    }
    figure {
      cursor: zoom-out;
      height: 100%;
      max-height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
    .gallery__ctrl {
      display: none;
    }
    .overlay__close-button {
      background-color: $white;
      position: absolute;
      top: 24px;
      right: 24px;
      @include responsive('medium') {
        top: 28px;
        right: 48px;
      }
    }
  }
  &__header {
    padding: 16px 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
    @include responsive('large') {
      padding: 28px 0 8px;
    }
  }
  &__close-button {
    background: transparent;
    align-items: center;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-left: auto;
    transform: scale(0.75);
    transform-origin: right;
    transition: .1s ease-in all;
    &::after {
      content: url('../images/icons/cancel.svg');
    }
    @include responsive('large') {
      transform: scale(1);
    }
  }
  &__project-information .main {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 152px;
    padding: 48px 0 56px;
    @include responsive('large') {
      grid-template-columns: .6fr 1fr;
      padding: 96px 0 72px;
    }
    .sub-header {
      padding: 20px 0 20px;
      @include responsive('large') {
        padding: 20px 0 40px;
      }
    }
    .tags {
      column-gap: 14px;
      display: flex;
      flex-wrap: wrap;
      row-gap: 14px;
      text-transform: capitalize;
    }
  }
  &__project-details {
    font-size: 0.906rem;
    padding-top: 48px;
    @include responsive('large') {
      padding-top: 0;
    }
    li {
      border-bottom: 1px solid rgba($navy-blue, .1);
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      &:first-child {
        padding-top: 10px;
      }
      @include responsive('large') {
        padding: 24px 0;
      }
    }
    span {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      font-size: .95rem;
      text-transform: capitalize;
      &.label {
        font-weight: 500;
        padding-right: 40px;
      }
      &:not(.label) {
        justify-content: flex-end;
      }
    }
  }
  &__wrap {
    background: linear-gradient(to top, rgba(238, 238, 238, .43) 7%, transparent 11%);
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;
    @include responsive('medium') {
      margin-bottom: 64px;
    }
  }
  &__related-projects {
    h4 {
      margin-bottom: 32px;
    }
    .grid {
      --column-count: 1;
      column-count: auto;
      display: grid;
      grid-template-columns: repeat(var(--column-count), 1fr);
      padding-bottom: 40px;
      @include responsive('medium') {
        --column-count: 5;
        grid-template-columns: repeat(var(--column-count), 1fr);
        padding-bottom: 72px;
      }
    }
  }
}