* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: $white;
}

ul {
  list-style-type: none;
}

::placeholder {
  color: rgba($navy-blue, 0.45);
}

input:focus {
  outline: none;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 500;
}

hr {
  background: rgba($navy-blue, 0.15);
  border: none;
  height: 1px;
}

img {
  max-width: 100%;
}

:root {
  font-size: .95rem;
  @include responsive('small') {
    font-size: 1rem;
  }
}