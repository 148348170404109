.header {
  color: $white;
  font-weight: 500;
  mix-blend-mode: difference;
  position: sticky;
  padding: 22px 0;
  top: 0;
  z-index: 1;
  a:focus-visible {
    outline: 2px solid #d27e00
  }
  @include responsive('small') {
    padding: 28px 0;
  }
}